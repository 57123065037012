<template>
    <div class="AccessControlView">
      <QrcodeStream @decode="onDecode" v-if="scanning" />
      <div class="scanning" v-if="scanning">
        <div class="box"></div>
        <div class="close">
          <v-btn rounded @click="scanning = false" color="#d5c57f" class="v-btn--size-x-large">Cerrar</v-btn>
        </div>
      </div>
        <v-container fluid style="height: 100%" v-if="!scanning">
          <v-row>
            <v-col align-self="center" align="center"><v-img class="my-5" src="@/assets/republica.svg" height="150px" /></v-col>
          </v-row>
          <v-row v-if="!success">
            <v-col align-self="center" align="center">
              <h1>Control de Acceso</h1>
            </v-col>
          </v-row>
          <v-row v-if="!start && !success">
            <v-col align-self="center" align="center" class="my-10 py-10">
              <div class="my-4">
              <v-btn rounded @click="getChallenge()" color="#d5c57f" class="v-btn--size-x-large">Iniciar</v-btn>
              </div>
              <div class="my-4">
              <v-btn rounded @click="scan()" color="#d5c57f" class="v-btn--size-x-large">Escanear</v-btn>
              </div>
            </v-col>
          </v-row>
          <v-row v-if="!success && start">
            <v-col align-self="center" align="center">
              <div class="my-10">
                <div>
                  <v-img class="qr" v-if="qr" :src="qr" width="250px" height="250px" aspect-ratio="1" style="border-radius: 16px" contain />
                </div>
                <v-progress-circular v-if="!qr" indeterminate width="20" color="white" size="250" />
                <div v-if="qr && isMobile() && false" class="icon-mobile mt-5 cursor-pointer" @click="openApp">
                  <v-img v-if="$device.ios" :src="require('@/assets/icon-ios.png')" contain width="32px" height="32px"></v-img>
                  <v-img v-if="$device.android" :src="require('@/assets/icon-android.png')" contain width="32px" height="32px"></v-img>
                </div>
                <div class="caption mt-1" v-if="qr && isMobile() && false">
                  Click to open <strong>iD4Good</strong>
                </div>
              </div>
            </v-col>
          </v-row>
          <v-row v-if="success">
            <v-col align-self="center" align="center">
              <h1 class="text-red" v-if="!card">No tiene Membresia</h1>
              <h1 class="text-green" v-if="card">Si tiene Membresia</h1>
              <div class="my-10">
                <v-img class="card" src="@/assets/cards/republica-infinite.png" width="350px" v-if="category === 'infinite'"></v-img>
                <v-img class="card" src="@/assets/cards/republica-signature.png" width="350px" v-else-if="category === 'signature'"></v-img>
                <v-img class="card" src="@/assets/cards/republica-platinum-1.png" width="350px" v-else-if="category === 'platinum'"></v-img>
                <h2 v-else-if="!category">
                  No tiene categoria asignada
                </h2>
              </div>
              <div v-if="balance" class="mt-5">
                <h4>Balance</h4>
                <h2 class="font-weight-bold">{{balance.toFixed(2) }} Puntos</h2>
              </div>
            </v-col>
          </v-row>
          <v-row>
            <v-col align-self="center" align="center">
              <v-btn rounded @click="reload()" color="white">Reiniciar</v-btn>
            </v-col>
          </v-row>
          <v-row>
            <v-col align-self="center" align="center" class="mt-10">
              <v-img src="@/assets/parriba.svg" height="20px" />
              <v-img src="@/assets/bloqs4good.svg" height="20px" class="mt-4" />
            </v-col>
          </v-row>
        </v-container>
    </div>
</template>

<script>
import './_AccessControlView.scss'
import { utils } from 'ethers'
import { QrcodeStream } from 'qrcode-reader-vue3'
import moment from 'moment'

export default {
  name: 'AccessControlView',
  components: { QrcodeStream },
  created () {
    const stringManifest = JSON.stringify(this.manifest)
    const blob = new Blob([stringManifest], { type: 'application/json' })
    const manifestURL = URL.createObjectURL(blob)
    document.querySelector('[rel="manifest"]').setAttribute('href', manifestURL)
  },
  mounted () {
    this.$vuetify.theme.dark = true
  },
  data () {
    return {
      start: false,
      error: false,
      qr: null,
      success: false,
      links: {},
      iD: null,
      card: false,
      category: false,
      balance: 0,
      scanning: false,
      manifest:
        {
          id: '#/republica/control',
          name: 'Republica Control',
          short_name: 'Republica',
          scope: 'https://panel.parriba.club/',
          start_url: 'https://panel.parriba.club/#/republica/payment',
          display: 'standalone',
          icons: [
            {
              src: require('@/assets/republica.favicon.192.png'),
              sizes: '192x192',
              type: 'image/png'
            },
            {
              src: require('@/assets/republica.favicon.512.png'),
              sizes: '512x512',
              type: 'image/png'
            }
          ],
          background_color: 'black',
          theme_color: '#000000'
        }
    }
  },
  methods: {
    async onDecode (data) {
      this.iD = data
      this.card = (await this.axios.get('https://4good.network/api/thirdparty/parriba/cards/' + this.iD + '/type/102')).data
      const card = (await this.axios.get('https://4good.network/api/thirdparty/parriba/cards/' + this.iD + '/data')).data
      if (card.data.category) {
        if (card.data.category.expire > moment().unix()) {
          this.category = card.data.category.name
        }
      }
      const balance = (await this.axios.get('https://4good.network/api/network/addresses/' + this.iD + '/balance/102/type')).data
      this.balance = parseFloat(utils.formatUnits(balance.balance, balance.decimals))
      this.scanning = false
      this.success = true
    },
    reload () {
      window.location.reload()
    },
    openApp () {
      if (this.$device.ios) {
        window.location.href = this.links.ios
      }
      if (this.$device.android) {
        window.location.href = this.links.android
      }
    },
    scan () {
      this.scanning = true
    },
    async getChallenge () {
      this.start = true
      const result = await this.axios.post('https://4good.network/api/identification')
      const challenge = result.data
      if (challenge) {
        this.qr = challenge.qr
        this.links = challenge.links
        try {
          let wait = await this.axios.get('https://4good.network/api/identification/' + challenge.hash + '/wait')
          if (wait.data.state === 'scanning' || wait.data.state === 'waiting') {
            this.qr = null
            wait = await this.axios.get('https://4good.network/api/identification/' + challenge.hash + '/wait')
          }
          if (wait.data.state === 'success') {
            this.iD = wait.data.data.iD
            this.card = (await this.axios.get('https://4good.network/api/thirdparty/parriba/cards/' + this.iD + '/type/102')).data
            const card = (await this.axios.get('https://4good.network/api/thirdparty/parriba/cards/' + this.iD + '/data')).data
            if (card.data.category) {
              if (card.data.category.expire > moment().unix()) {
                this.category = card.data.category.name
              }
            }
            const balance = (await this.axios.get('https://4good.network/api/network/addresses/' + this.iD + '/balance/102/type')).data
            this.balance = parseFloat(utils.formatUnits(balance.balance, balance.decimals))
            this.success = true
          }
        } catch (e) {
          this.qr = null
        }
      }
    },
    isMobile () {
      return this.$device.mobile
    }
  }
}
</script>
