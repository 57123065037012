import { initializeApp } from 'firebase/app'
import { getFirestore } from 'firebase/firestore'
import { getAuth } from 'firebase/auth'

const firebaseConfig = {
  apiKey: 'AIzaSyADBWz5y_jRr_IqpkqKHEypKc4m7G-qb3Y',
  authDomain: 'fourgoodnetwork.firebaseapp.com',
  projectId: 'fourgoodnetwork',
  storageBucket: 'fourgoodnetwork.appspot.com',
  messagingSenderId: '257571429478',
  appId: '1:257571429478:web:b6da74157105418c10e1a7',
  measurementId: 'G-GPEGMSP2XV'
}

const firebaseApp = initializeApp(firebaseConfig)
const auth = getAuth(firebaseApp)
const firestore = getFirestore(firebaseApp)
export { firestore, auth }
