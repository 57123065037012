<template>
    <div class="MainView">
        <pre v-text="$attrs"/>
    </div>
</template>

<script>
export default {
  name: 'MainView'
}
</script>
