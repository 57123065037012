<template>
    <div class="PaymentControlView">
      <v-container v-if="!ready" fluid style="height: 100%">
        <v-row>
          <v-col align-self="center" align="center"><v-img class="my-5" src="@/assets/republica.svg" height="100px" /></v-col>
        </v-row>
        <v-row>
          <v-col align-self="center" align="center" style="background: #ffffff11; border-radius: 15px">
            <div style="max-width: 500px" class="my-15">
              <v-text-field label="Nombre Empleado" type="string" solo color="white" v-model="employee.name" />
              <v-text-field label="DNI Empleado" type="string" solo color="white" v-model="employee.dni" />
            </div>
          </v-col>
        </v-row>
        <v-row>
          <v-col align-self="center" align="center">
            <div class="my-5">
              <v-btn rounded color="#d5c57f" class="v-btn--size-x-large" @click="save">Comenzar</v-btn>
            </div>
          </v-col>
        </v-row>
        <v-row>
          <v-col align-self="center" align="center" class="mt-3">
            <div style="color: red" v-if="demo">
              DEMO
            </div>
            <v-img src="@/assets/parriba.svg" height="20px" />
            <v-img src="@/assets/bloqs4good.svg" height="20px" class="mt-4" />
          </v-col>
        </v-row>
      </v-container>
      <v-container v-if="ready" fluid style="height: 100%">
        <v-row>
          <v-col align-self="center" align="center"><v-img class="my-5" src="@/assets/republica.svg" height="100px" /></v-col>
        </v-row>
        <v-row v-if="!pay">
          <v-col align-self="center" align="center" style="background: #ffffff11; border-radius: 15px">
            <div style="max-width: 500px">
              <v-text-field label="Monto" type="number" solo suffix="Puntos" color="white" v-model="amount" />
              <v-text-field hide-details label="Propina" type="number" solo suffix="Puntos" color="white" v-model="tips" />
            </div>
          </v-col>
        </v-row>
        <v-row v-if="pay && !success && !failed">
          <v-col align-self="center" align="center" class="my-15">
            <div>
              <v-img class="qr" v-if="pay.qr" :src="pay.qr" width="250px" height="250px" aspect-ratio="1" style="border-radius: 16px" contain />
            </div>
            <v-progress-circular v-if="!pay.qr" indeterminate width="20" color="white" size="250" />
            <div>
              <div class="mt-5">
                <h2>Total a Pagar</h2>
                <h1>{{ total }} Puntos</h1>
              </div>
            </div>
          </v-col>
        </v-row>
        <v-row v-if="pay && success">
          <v-col align-self="center" align="center" class="my-15">
            <div>
              <h1 class="text-green my-10">Pago exitoso</h1>
            </div>
            <div class="my-5">
              <h4>Código de transacción</h4>
              <h3>{{ processing.shortTx }}</h3>
              <div>
                <div class="mt-5">
                  <h1>Resumen</h1>
                  <div>
                    <h5>Monto</h5>
                    <h3>{{ amount }} Puntos</h3>
                  </div>
                  <div>
                    <h5>Propina</h5>
                    <h3>{{ tips }} Puntos</h3>
                  </div>
                  <div class="mt-5">
                    <h2>Total</h2>
                    <h1>{{ total }} Puntos</h1>
                  </div>
                </div>
              </div>
            </div>
            <v-btn rounded @click="reload()" color="#d5c57f" class="my-2 v-btn--size-x-large">Nuevo Pago</v-btn>
          </v-col>
        </v-row>
        <v-row v-if="pay && failed">
          <v-col align-self="center" align="center" class="my-15">
            <div>
              <h1 class="text-red my-10">Pago Fallido</h1>
              <v-btn rounded @click="retry()" color="white" class="v-btn--size-x-small">Reintentar</v-btn>
            </div>
          </v-col>
        </v-row>
        <v-row v-if="!pay">
          <v-col align-self="center" align="center">
            <h1>Resumen</h1>
            <div>
              <h5>Monto</h5>
              <h3>{{ amount }} Puntos</h3>
            </div>
            <div>
              <h5>Propina</h5>
              <h3>{{ tips }} Puntos</h3>
            </div>
            <div class="mt-5">
              <h2>Total</h2>
              <h1>{{ total }} Puntos</h1>
            </div>
          </v-col>
        </v-row>
        <v-row v-if="!pay">
          <v-col align-self="center" align="center">
            <div class="my-5">
              <v-btn rounded color="#d5c57f" class="v-btn--size-x-large" @click="generatePay">Cobrar</v-btn>
            </div>
            <h5>Empleado <strong>{{ employee.name }}</strong></h5>
          </v-col>
        </v-row>
        <v-row>
          <v-col align-self="center" align="center" class="mb-2">
            <v-container style="max-width: 500px">
              <v-row>
                <v-col>
                  <v-btn rounded @click="reload()" color="white" class="v-btn--size-x-small">Reiniciar</v-btn>
                </v-col>
                <v-col>
                  <v-btn rounded @click="clear()" color="white" class="v-btn--size-x-small">Cerrar Punto</v-btn>
                </v-col>
              </v-row>
            </v-container>
            <div style="color: red" v-if="demo">
              DEMO
            </div>
            <v-img src="@/assets/parriba.svg" height="20px" />
            <v-img src="@/assets/bloqs4good.svg" height="20px" class="mt-4" />
          </v-col>
        </v-row>
      </v-container>
    </div>
</template>

<script>
import './_PaymentControlView.scss'
export default {
  name: 'PaymentControlView',
  props: {
    demo: Boolean
  },
  data () {
    return {
      merchant: {
        type: 102,
        code: 'republica',
        name: 'Republica By. BOIRE',
        description: 'Canje de productos o servicios'
      },
      employee: {
        name: '',
        dni: ''
      },
      ready: false,
      failed: false,
      processing: false,
      success: false,
      amount: 0,
      tips: 0,
      pay: false,
      manifest:
        {
          id: '#/republica/payment',
          name: 'Republica Access',
          short_name: 'Republica',
          scope: 'https://panel.parriba.club/',
          start_url: 'https://panel.parriba.club/#/republica/payment',
          display: 'standalone',
          icons: [
            {
              src: require('@/assets/republica.favicon.192.png'),
              sizes: '192x192',
              type: 'image/png'
            },
            {
              src: require('@/assets/republica.favicon.512.png'),
              sizes: '512x512',
              type: 'image/png'
            }
          ],
          background_color: 'black',
          theme_color: '#000000'
        }
    }
  },
  created () {
    const stringManifest = JSON.stringify(this.manifest)
    const blob = new Blob([stringManifest], { type: 'application/json' })
    const manifestURL = URL.createObjectURL(blob)
    document.querySelector('[rel="manifest"]').setAttribute('href', manifestURL)
  },
  mounted () {
    try {
      const employee = JSON.parse(localStorage.getItem('employee'))
      if (employee) {
        this.employee = employee
        this.ready = true
      }
    } catch (e) {
    }
  },
  computed: {
    total () {
      return parseFloat(this.amount) + parseFloat(this.tips)
    }
  },
  methods: {
    async generatePay () {
      this.pay = { qr: false }
      const payment = (await this.axios.post('https://4good.network/api/thirdparty/parriba/sales', {
        amount: this.amount,
        tips: this.tips,
        merchant: this.merchant,
        employee: this.employee.dni
      })).data
      this.pay.qr = payment.qr
      if (this.demo) {
        setTimeout(() => {
          this.success = true
          this.processing = { shortTx: 'demo_hash_112233445566' }
        }, 3000)
        return
      }
      let wait = await this.axios.get('https://4good.network/api/identification/signatures/' + payment.hash + '/wait')
      if (wait.data.state === 'scanning' || wait.data.state === 'waiting') {
        this.pay.qr = null
        wait = await this.axios.get('https://4good.network/api/identification/signatures/' + payment.hash + '/wait')
      }
      if (wait.data.state === 'success') {
        try {
          const processing = (await this.axios.post('https://4good.network/api/thirdparty/parriba/sales/processing', {
            hash: payment.hash
          })).data
          console.log(processing)
          if (processing.state === 'paid') {
            this.success = true
            this.processing = processing
            return
          }
          this.failed = true
        } catch (e) {
          this.failed = true
        }
        return
      }
      this.failed = true
    },
    retry () {
      this.success = false
      this.failed = false
      this.pay = false
      this.processing = false
    },
    clear () {
      localStorage.clear()
      this.reload()
    },
    save () {
      localStorage.setItem('employee', JSON.stringify(this.employee))
      this.ready = true
    },
    reload () {
      window.location.reload()
    }
  }
}
</script>
