<template>
    <div class="DashboardControlView">
      <v-container class="fill-height">
        <v-row>
          <v-col v-if="failed" align-self="center" align="center">
            <div>
              <div class="caption" style="font-size: 1.2rem">
                You do <strong>not have permission</strong> to enter this section
              </div>
            </div>
          </v-col>
          <v-col v-if="!success && !failed" align-self="center" align="center">
            <div>
              <div>
                <v-img class="qr" v-if="qr" :src="qr" width="250px" height="250px" aspect-ratio="1" style="border-radius: 16px" contain />
              </div>
              <v-progress-circular v-if="!qr" indeterminate width="20" color="white" size="250" />
              <div class="caption" v-if="qr" style="font-size: 0.8rem">
                scan with <strong>iD4G+</strong>
              </div>
            </div>
          </v-col>
          <v-col v-if="success && !failed && show.card" align-self="center" align="center">
            <div>Tarjetas de Miembro</div>
            <div align="right" class="my-1">
              <v-btn small @click="getCards" color="green">Update</v-btn>
            </div>
            <v-data-table style="background: #ffffffaa"
              :items-per-page="10"
              :headers="cards.headers"
              :items="cards.data"
              class="elevation-1"
              show-expand :sort-by="[{ key: 'created', order: 'desc' }]"
            >
              <template v-slot:expanded-row="{ columns, item }">
                <tr>
                  <td :colspan="columns.length">
                <v-container>
                  <v-row>
                    <v-col>
                      <div><strong>iD:</strong> {{ item.columns.iD }}</div>
                      <div><strong>Owner:</strong> {{ item.raw.owner }}</div>
                      <div v-if="item.raw.client"><strong>Nombre:</strong> {{  item.raw.client.name }}</div>
                      <div v-if="item.raw.client"><strong>DNI:</strong> {{  item.raw.client.dni }}</div>
                      <div v-if="item.raw.client"><strong>Correo:</strong> {{  item.raw.client.email }}</div>
                      <div v-if="item.raw.client"><strong>Teléfono:</strong> {{  item.raw.client.phone }}</div>
                      <div v-if="item.raw.client"><strong>Cumpleaños:</strong> {{  item.raw.client.birthdate }}</div>
                    </v-col>
                  </v-row>
                </v-container>
                  </td>
                </tr>
              </template>
              <template v-slot:[`item.iD`]="{ item }">
                ...{{ item.columns.iD.slice(-12) }}
              </template>
              <template v-slot:[`item.created`]="{ item }">
                {{ getDateFormat(item.columns.created) }}
              </template>
            </v-data-table>
          </v-col>
          <v-col v-if="success && !failed && show.activity" align-self="center" align="center">
            <div>Actividad de Republica</div>
            <div align="right" class="my-1">
              <v-btn small @click="getActivities" color="green">Update</v-btn>
            </div>
            <v-data-table style="background: #ffffffaa"
                          :items-per-page="10"
                          :headers="activities.headers"
                          :items="activities.data"
                          class="elevation-1"
                          show-expand
            >
              <template v-slot:expanded-row="{ columns, item }">
                <tr>
                  <td :colspan="columns.length">
                    <v-container>
                      <v-row>
                        <v-col>
                          <div><strong>iD:</strong> {{ item.columns.iD }}</div>
                          <div v-if="item.raw.track"><strong>Código de Transacción:</strong> {{  item.raw.track }}</div>
                          <div v-if="item.raw.amount > 0"><strong>Monto:</strong> {{  item.raw.amount }} Puntos</div>
                          <div v-if="item.raw.tips > 0"><strong>Propina:</strong> {{  item.raw.tips }} Puntos</div>
                          <div v-if="item.raw.amount > 0"><strong>Empleado:</strong> {{  item.raw.processor }}</div>
                        </v-col>
                      </v-row>
                    </v-container>
                  </td>
                </tr>
              </template>
              <template v-slot:[`item.iD`]="{ item }">
                ...{{ item.columns.iD ? item.columns.iD.slice(-12) : 'Anonimo' }}
              </template>
              <template v-slot:[`item.created`]="{ item }">
                {{ getDateFormat(item.columns.created) }}
              </template>
              <template v-slot:[`item.type`]="{ item }">
                <div v-if="item.columns.type === 'get_card'">Obtener Tarjeta</div>
                <div v-if="item.columns.type === 'buy_service'">Canjear Servicio</div>
                <div v-if="item.columns.type === 'buy_points'">Canjear Puntos</div>
                <div v-if="item.columns.type === 'sell_points'">Venta Directa de Puntos</div>
              </template>
            </v-data-table>
          </v-col>
          <v-col v-if="success && !failed && show.recharge" align-self="center" align="center">
            <div>Recarga de Puntos Republica</div>
            <div align="right" class="my-1">
              <v-btn small @click="getChallengeRecharge" color="green">Reset</v-btn>
            </div>
            <div align="center" class="my-5" v-if="recharge.loading">
              <v-progress-circular indeterminate width="20" color="white" size="250" />
            </div>
            <div v-if="!recharge.loading">
              <div align="center" class="my-5" v-if="!recharge.iD">
                    <v-img class="qr" v-if="recharge.qr" :src="recharge.qr" width="250px" height="250px" aspect-ratio="1" style="border-radius: 16px" contain />
                    <v-progress-circular v-if="!recharge.qr" indeterminate width="20" color="white" size="250" />
                    <div class="caption" v-if="recharge.qr" style="font-size: 0.8rem">
                      scan with <strong>iD4G+</strong>
                    </div>
              </div>
              <div align="center" class="my-5" v-if="recharge.iD && !recharge.completed && !recharge.failed">
                <div class="my-4 py-2" style="background: #ffffff11; border-radius: 16px; max-width: 500px">
                  <v-text-field label="iD" v-model="recharge.iD" class="px-4" readonly />
                  <v-text-field label="Amount" type="number" :min="5" :max="10000" v-model="recharge.amount" class="px-4"/>
                  <v-btn rounded @click="sendToken(102)" color="green"  class="my-10">Enviar Puntos Republica</v-btn>
                </div>
              </div>
              <div align="center" class="my-5" v-if="recharge.iD && recharge.completed">
                <div class="caption" style="font-size: 1.2rem; color: green">
                  Recarga exitosa
                </div>
              </div>
              <div align="center" class="my-5" v-if="recharge.iD && recharge.failed">
                <div class="caption" style="font-size: 1.2rem; color: red">
                  Recarga fallida
                </div>
              </div>
            </div>
          </v-col>
          <v-col v-if="success && !failed && show.tx" align-self="center" align="center">
            <div>Validar Transacción</div>
            <div align="center" class="my-10 px-10" v-if="!txData">
                <v-text-field v-model="tx" label="Identificador de Transacción" outlined />
                <v-btn small @click="verifyTransaction" color="green">Verificar</v-btn>
            </div>
            <div align="center" class="my-10 px-10" v-if="txData === 404">
              <h1 style="color: orange" class="my-10">Transacción no encontrada</h1>
              <v-btn small @click="showValidateTransaction" color="green">Reset</v-btn>
            </div>
            <div align="center" class="my-10 px-10" v-if="typeof txData === 'object'">
              <h1 style="color: green" class="my-10">Transacción encontrada</h1>
              <div class="my-2">
                <div><strong>Creada:</strong> {{ getDateFormat(txData.created) }}</div>
                <div><strong>Actualizada:</strong> {{ getDateFormat(txData.updated) }}</div>
                <div><strong>iD:</strong> {{ txData.iD }}</div>
                <div><strong>Código de Transacción:</strong> {{ txData.track }}</div>
                <div><strong>Estado:</strong> {{ txData.state }}</div>
                <div><strong>Monto:</strong> {{ txData.amount }} Puntos</div>
                <div><strong>Propina:</strong> {{ txData.tips }} Puntos</div>
                <div><strong>Total:</strong> {{ parseFloat(txData.amount) + parseFloat(txData.tips) }} Puntos</div>
              </div>
              <v-btn small @click="showValidateTransaction" color="green">Reset</v-btn>
            </div>

          </v-col>
        </v-row>
      </v-container>
      <v-navigation-drawer v-if="success"
        expand-on-hover
        permanent
        rail dark style="background: black; color: white"
      >
        <v-list>
          <v-img src="@/assets/republica.png"></v-img>
        </v-list>

        <v-divider></v-divider>

        <v-list density="compact" nav>
          <v-list-item @click="showCards" :active="show.card" prepend-icon="mdi-wallet-giftcard" title="Tarjetas" value="cards"></v-list-item>
          <v-list-item @click="showActivities" :active="show.activity" prepend-icon="mdi-file-table-outline" title="Actividad" value="activity"></v-list-item>
          <v-list-item @click="showRecharge" :active="show.recharge" prepend-icon="mdi-wallet-giftcard" title="Recarga" value="activity"></v-list-item>
          <v-list-item @click="showValidateTransaction" :active="show.tx" prepend-icon="mdi-check-decagram" title="Validar Transacción" value="activity"></v-list-item>
        </v-list>
        <v-divider></v-divider>
        <v-list density="compact" nav>
          <v-list-item prepend-icon="mdi-logout" title="Logout" value="logout"></v-list-item>
        </v-list>
      </v-navigation-drawer>
    </div>
</template>

<script>
import './_DashboardControlView.scss'
import { auth, firestore } from '@/plugins/firebase'
import { signInWithCustomToken, signOut } from 'firebase/auth'
import { collection, getDocs, query, where } from 'firebase/firestore'
import moment from 'moment'

export default {
  name: 'DashboardControlView',
  data () {
    return {
      tx: '',
      txData: false,
      success: false,
      qr: false,
      panel: false,
      failed: false,
      recharge: {
        qr: false,
        iD: false,
        amount: 0,
        completed: false,
        failed: false,
        loading: false
      },
      show: {
        card: true,
        activity: false,
        recharge: false,
        tx: false
      },
      activities: {
        data: [],
        headers: [
          { title: 'iD', key: 'iD' },
          { title: 'Creado', key: 'created' },
          { title: 'Estado', key: 'state' },
          { title: 'Tipo', key: 'type' }
        ]
      },
      cards: {
        data: [],
        headers: [
          { title: 'iD', key: 'iD' },
          { title: 'Creado', key: 'created' },
          { title: 'Nombre', key: 'data.clientName' },
          { title: 'Miembro', key: 'data.member' },
          { title: 'Correo', key: 'data.clientEmail' },
          { title: 'Categoria', key: 'data.category.name' }
        ]
      }
    }
  },
  async mounted () {
    if (this.$store.state.token) {
      if (await signInWithCustomToken(auth, this.$store.state.token)) {
        this.success = true
        await this.getCards()

        return
      }
    }
    this.getChallenge()
  },
  methods: {
    async sendToken (type) {
      this.recharge.loading = true
      try {
        this.send = (await this.axios.put('https://4good.network/api/thirdparty/parriba/send/' + this.recharge.iD + '/type/' + type, {
          amount: this.recharge.amount,
          session: this.$store.state.session.session,
          iD: this.$store.state.iD
        })).data
        this.recharge.completed = true
      } catch (e) {
        this.recharge.failed = true
      }
      this.recharge.loading = false
    },
    showCards () {
      console.log('showCards')
      this.show.activity = false
      this.show.recharge = false
      this.show.tx = false
      this.show.card = true
      this.getCards()
    },
    showRecharge () {
      console.log('showARecharge')
      this.show.recharge = true
      this.show.activity = false
      this.show.tx = false
      this.show.card = false
      this.getChallengeRecharge()
    },
    showActivities () {
      console.log('showActivities')
      this.show.activity = true
      this.show.recharge = false
      this.show.tx = false
      this.show.card = false
      this.getActivities()
    },
    showValidateTransaction () {
      console.log('showValidateTransaction')
      this.show.activity = false
      this.show.recharge = false
      this.show.tx = true
      this.show.card = false
      this.tx = ''
      this.txData = false
    },
    getDateFormat (timestamp) {
      return moment.unix(timestamp).format('YYYY/MM/DD HH:mm')
    },
    async logout () {
      signOut(auth)
      this.$store.state.session = false
      this.$store.state.iD = false
      this.$store.state.token = false
      window.location.reload()
    },
    async verifyTransaction () {
      const activitiesRef = query(collection(firestore, 'RedeemActivity'), where('merchant', '==', 'republica'), where('track', '==', this.tx))
      const activitiesSnapshot = await getDocs(activitiesRef)
      const data = activitiesSnapshot.docs.map(doc => doc.data())
      if (data.length > 0) {
        this.txData = data.pop()
        return
      }
      this.txData = 404
    },
    async getActivities () {
      this.activities.data = []
      const activitiesRef = query(collection(firestore, 'RedeemActivity'), where('merchant', '==', 'republica'))
      const activitiesSnapshot = await getDocs(activitiesRef)
      const data = activitiesSnapshot.docs.map(doc => doc.data())
      for (const item of data) {
        this.activities.data.push({
          iD: item.iD,
          created: item.created,
          state: item.state,
          type: item.type,
          track: item.track,
          tips: item.tips,
          amount: item.amount,
          processor: item.processor
        })
      }
    },
    async getCards () {
      this.cards.data = []
      const cardsRef = query(collection(firestore, 'Cards'), where('type', '==', '102'))
      const cardsSnapshot = await getDocs(cardsRef)
      this.cards.data = cardsSnapshot.docs.map(doc => doc.data())
    },
    async getChallengeRecharge () {
      this.recharge.qr = false
      this.recharge.iD = false
      this.recharge.completed = false
      this.recharge.failed = false
      this.recharge.amount = 0
      const result = await this.axios.post('https://4good.network/api/identification')
      const challenge = result.data
      if (challenge) {
        try {
          this.recharge.qr = challenge.qr
          let wait = await this.axios.get('https://4good.network/api/identification/' + challenge.hash + '/wait')
          if (wait.data.state === 'scanning' || wait.data.state === 'waiting') {
            this.recharge.qr = null
            wait = await this.axios.get('https://4good.network/api/identification/' + challenge.hash + '/wait')
          }
          if (wait.data.state === 'success') {
            this.recharge.qr = false
            this.recharge.iD = wait.data.data.iD
          }
        } catch (e) {
          if (this.recharge.iD) {
            return
          }
          this.recharge.qr = null
          this.getChallengeRecharge()
        }
      }
    },
    async getChallenge () {
      const result = await this.axios.post('https://4good.network/api/identification')
      const challenge = result.data
      if (challenge) {
        try {
          this.qr = challenge.qr
          let wait = await this.axios.get('https://4good.network/api/identification/' + challenge.hash + '/wait')
          if (wait.data.state === 'scanning' || wait.data.state === 'waiting') {
            this.qr = null
            wait = await this.axios.get('https://4good.network/api/identification/' + challenge.hash + '/wait')
          }
          if (wait.data.state === 'success') {
            this.qr = false
            this.$store.state.iD = wait.data.data.iD
            setTimeout(async () => {
              this.next = true
              this.$store.state.session = (await this.axios.post('https://4good.network/api/identification/' + challenge.hash + '/session')).data
              try {
                this.$store.state.token = (await this.axios.post('https://4good.network/api/thirdparty/parriba/' + this.$store.state.iD + '/access', {
                  session: this.$store.state.session.session,
                  merchant: 'republica'
                })).data
              } catch (e) {
                this.failed = true
                return
              }
              const login = await signInWithCustomToken(auth, this.$store.state.token)
              this.success = true
              console.log(login)
              await this.getCards()
            }, 3000)
            setTimeout(() => {
              this.panel = true
            }, 3200)
            // Redirect
          }
        } catch (e) {
          if (this.$store.state.session) {
            return
          }
          this.qr = null
          this.getChallenge()
        }
      }
    }
  }
}
</script>
