import { createRouter, createWebHashHistory } from 'vue-router'
import MainView from '@/views/MainView/MainView'
import AccessControlView from '@/views/Republica/AccessControlView/AccessControlView.vue'
import PaymentControlView from '@/views/Republica/PaymentControlView/PaymentControlView.vue'
import SendView from '@/views/SendView/SendView.vue'
import DashboardControlView from '@/views/Republica/DashboardControlView/DashboardControlView.vue'

const routes = [
  {
    path: '/',
    name: 'home',
    component: MainView
  },
  {
    path: '/send',
    name: 'send',
    component: SendView
  },
  {
    path: '/republica/dashboard',
    name: 'republicaDashboard',
    component: DashboardControlView
  },
  {
    path: '/republica/control',
    name: 'republicaControl',
    component: AccessControlView
  },
  {
    path: '/republica/payment',
    name: 'republicaPayment',
    component: PaymentControlView,
    props: { demo: false }
  },
  {
    path: '/republica/payment/demo',
    name: 'republicaPaymentDemo',
    component: PaymentControlView,
    props: { demo: true }
  }
]

const router = createRouter({
  history: createWebHashHistory(),
  routes
})

export default router
