<template>
    <div class="SendView">
      <QrcodeStream @decode="onDecode" v-if="scanning" />
      <div class="scanning" v-if="scanning">
        <div class="box"></div>
        <div class="close">
          <v-btn rounded @click="scanning = false" class="v-btn--size-x-large">Cerrar</v-btn>
        </div>
      </div>
      <v-container v-if="!scanning">
        <v-row>
          <v-col align-self="center" align="center">
            <div class="my-4 py-2" style="background: white; border-radius: 16px; max-width: 500px">
              <v-text-field label="iD" v-model="iD" class="px-4"/>
              <v-text-field label="Amount" type="number" v-model="amount" class="px-4"/>
            </div>
            <div class="my-2">
            <v-btn rounded @click="scan()"  class="v-btn--size-x-large">Escanear</v-btn>
            </div>
            <div class="my-2">
            <v-btn rounded @click="sendToken('p4g-pen')"  class="v-btn--size-x-large">Enviar Puntos P'Arriba</v-btn>
            </div>
            <div class="my-2">
            <v-btn rounded @click="sendToken('p4g-c-rep')"  class="v-btn--size-x-large">Enviar Puntos Republica</v-btn>
            </div>
          </v-col>
        </v-row>
        <v-row>
          <v-col align-self="center" align="center" style="color: white">
            {{ send }}
          </v-col>
        </v-row>
        <v-row>
          <v-col align-self="center" align="center">
            <v-btn rounded @click="reload()" color="white">Reiniciar</v-btn>
          </v-col>
        </v-row>
      </v-container>
    </div>
</template>

<script>
import './_SendView.scss'
import { QrcodeStream } from 'qrcode-reader-vue3'
export default {
  name: 'SendView',
  components: { QrcodeStream },
  data () {
    return {
      scanning: false,
      send: '',
      iD: '',
      amount: 0
    }
  },
  methods: {
    async onDecode (data) {
      this.iD = data
    },
    scan () {
      this.scanning = true
    },
    reload () {
      window.location.reload()
    },
    async sendToken (type) {
      this.send = (await this.axios.put('https://4good.network/api/thirdparty/parriba/send/' + this.iD + '/type/' + type, {
        amount: 0
      })).data
    }
  }
}
</script>
